.skills-container{
    margin: 4rem 0;
    position: relative;
}
  
.skills-container::before,
.skills-container::after{
    content: " ";
    width: 28rem;
    height: 28rem;
    border-radius: 28.125rem;
    background: #7c66e3;
    position: absolute;
    z-index: -1;
    filter: blur(10rem);
}
  
.skills-container::after{
    top: -1rem;
    left: -2rem;
}
  
.skills-container::before{
    background: #9e51b9;
    bottom: 0rem;
    right: 0rem;
}
  
.skills-container h5{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3.5rem;
}
  
.skills-content{
    display: flex;
    align-items: flex-start;
    gap: 3.5rem;
}
  
.skills{
    flex: 1;
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
}
  
.skills-info{
    flex: 1;
}

@media (max-width: 768px) {
    .skills-content{
      flex-direction: column-reverse;
      gap: 2rem;
    }
    .skills,
    .skills-info{
      width: 100%;
      height: 50%;
    }
    .skills-container h5{
      font-size: 1.3rem;
    }
    .skills-container::after,
    .skills-container::before{
      width: 18rem;
      height: 18rem;
    }
}