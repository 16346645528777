@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

*{
  color: white;
  font-family: 'Poppins', 'sans-serif';
}

body {
  background-color: #000;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-container{
  max-width: 1300px !important;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 1300px) {
  .app-container{
    padding: 0 2rem;
  }
}
