.project-card{
    border-bottom: 1px solid #6751b9;
    display: flex;
    gap: 1rem;
}

.project-image{
    margin: 3rem 0.2rem;
}

.project-image img{
    margin: auto;
    align-items: center;
    justify-content: center;
    width: 20rem;
    height: auto;
}

.project-title{
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: #a892fe;
}

.project-github{
    padding: 0.5rem 1rem;
    margin: 0.3rem 2rem;
    font-size: 1rem;
    font-weight: 800;
    color: #fff;
    background: linear-gradient(90deg, #a892fe 0%, #8064e8 100%);
    border-radius: 0.6rem;
    border: 1px solid #6751b9;
    transition: all 0.3s ease;
    cursor: pointer;
}

.project-site{
    padding: 0.5rem 1rem;
    margin: 0.3rem 2rem;
    font-size: 1rem;
    font-weight: 800;
    color: #fff;
    background: linear-gradient(90deg, #a892fe 0%, #8064e8 100%);
    border-radius: 0.6rem;
    border: 1px solid #6751b9;
    transition: all 0.3s ease;
    cursor: pointer;
}

@media (max-width: 768px) {
    .project-image img{
        width: 9rem;
        height: auto;
    }
}