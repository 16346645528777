.project-container{
    margin: 4rem 0;
    position: relative;
}

.project-container::before,
.project-container::after{
    content: " ";
    width: 28rem;
    height: 28rem;
    border-radius: 28.125rem;
    background: #7c66e3;
    position: absolute;
    z-index: -1;
    filter: blur(10rem);
}
  
.project-container::after{
    top: -1rem;
    left: -2rem;
}
  
.project-container::before{
    background: #9e51b9;
    bottom: 0rem;
    right: 0rem;
}

.project-container h5{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3.5rem;
}

@media (max-width: 768px) {
    .contact-container h5{
        font-size: 1.3rem;
        margin-bottom: 0;
    }
    .project-container::after,
    .project-container::before{
      width: 18rem;
      height: 18rem;
    }
}
