.contact-form-content{
    width: 100%;
}
  
form{
    margin-top: 0.3rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
}
  
.name-container{
    display: flex;
    align-items: center;
    grid-gap: 1.5rem;
  }
  
form input,
form textarea{
    flex: 1;
    width: 97.4%;
    font-size: 0.9rem;
    color: #fff;
    background: #130f2a;
    border-radius: 0.6rem;
    border: 1.5px solid #6751b9;
    padding: 1rem 0.5rem;
}
  
form button{
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    background: linear-gradient(90deg, #a892fe 0%, #8064e8 100%);
    border-radius: 0.6rem;
    border: 1.5px solid #6751b9;
    padding-block: 1rem;
    transition: all 0.3s ease;
    cursor: pointer;
}
  
form button:is(:active,:hover,:focus){
    color: #a892fe;
    background: transparent;
}