.experience-container{
    margin: 4rem 0;
    position: relative;
}
  
.experience-container::before,
.experience-container::after{
    content: " ";
    width: 28rem;
    height: 28rem;
    border-radius: 28.125rem;
    background: #7c66e3;
    position: absolute;
    z-index: -1;
    filter: blur(14rem);
}
  
.experience-container::before{
    bottom: 0rem;
    right: 0rem;
}
  
.experience-container::after{
    background: #dd8cfa;
    top: 0rem;
    left: 0rem;
}
  
.experience-container h5{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem;
}
  
  
  
.arrow-left,
.arrow-right{
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6751b9;
    border-radius: 0.65rem;
    border: 1.5px solid #6751b9;
    background: transparent;
    position: absolute;
    top: 55%;
    z-index: 2;
    cursor: pointer;
}
  
.arrow-left span,
.arrow-right span{
    font-size: 2rem;
    font-weight: 500;
}
  
.arrow-left{
    left: -0.5rem;
}
  
.arrow-right{
    right: -0.5rem;
}
  
@media (max-width: 768px) {
    .experience-container::after,
    .experience-container::before{
      width: 18rem;
      height: 18rem;
    }
    .experience-container h5{
      font-size: 1.3rem;
      margin-bottom: 2rem;
    }
    .arrow-left{
      left: -1.2rem;
    }
    .arrow-right{
      right: -1.2rem;
    }
}