.skills-info-card{
    min-height: 20rem;
    border-radius: 0.65rem;
    border: 1.5px solid #6751b9;
    background: rgba(22, 17, 47, 0.398);
    backdrop-filter: blur(1rem);
}
  
.skills-info-card h6{
    text-align: center;
    padding: 0.8rem;
    margin: auto;
    font-size: 1.2rem;
    font-weight: 500;
    background: linear-gradient(180deg, #ac96ff 0%, #7c5fe6 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 1.5px solid #6751b9;
}
  
.skills-info-content{
    padding: 0.8rem 2rem;
}
  
.skill-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  
.skill-info p{
    font-size: 1rem;
    font-weight: 500;
}
  
.skill-info .percentage{
    color: #dd8cfa;
}
  
.skill-progress-bg{
    width: 100%;
    height: 0.8rem;
    background: #382e68;
    border-radius: 0.5rem;
    overflow: hidden;
}
  
.skill-progress{
    width: 0%;
    height: 0.8rem;
    background: linear-gradient(90deg, #aa94fe -50%, #7d60e6 50%);
    border-radius: 0.5rem;
    transition: all 0.5s ease-in-out;
}