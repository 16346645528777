.mobile-menu{
    margin-block-start: 5.5rem;
    width: 30vw;
    height: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;
    opacity: 0;
    transition: all 0.5s ease;
    transform: translateX(0);
}
  
.mobile-menu.active > .mobile-menu-container{
    width: 50vw;
    height: auto;
    background-color: #342864;
    padding: 2rem;
    border-radius: 2rem;
}
  
.mobile-menu.active{
    opacity: 1;
    transform: translateX(-40vw);
    margin-right: 1rem;
}
  
.mobile-menu ul{
    display: none;
}
  
.mobile-menu.active ul{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    list-style: none;
    margin-left: -2rem;
}
  
  
@media (max-width: 769px) {
    .mobile-menu{
      display: block;
    }
}