.nav-wrapper{
    width: auto;
    height: auto;
    background: transparent;
    padding: 0.5rem 0;
    position: sticky;
    top: 0;
    z-index: 30;
    backdrop-filter: blur(50px);
}
  
.nav-content{
    max-width: 1300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0 auto;
}
  
.logo{
    width: 12rem;
    height: auto;
}
  
.logo span{
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    color: #a993fe;
    
}
  
.nav-content ul{
    display: flex;
    align-items: center;
    list-style-type: none;
}
  
.nav-content li{
    margin: 0 1rem;
}
  
.menu-item{
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    position: relative;
    cursor: pointer;
}
  
.menu-item::before{
    content: " ";
    width: 2rem;
    height: 0.2rem;
    background: linear-gradient(90deg, #a993fe 0%, #7e61e7 100%);
    border-radius: 0.5rem;
    bottom: -0.6rem;
    position: absolute;
    opacity: 0;
    transform: translateX(-1.5rem);
    transition: all 0.3s ease;
}
  
.menu-item:hover::before{
    width: 100%;
    transform: translateX(0);
    opacity: 1;
}
  
  
.contact-btn{
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    margin: auto;
    margin-block-end: 1rem;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: linear-gradient(90deg, #a993fe 0%, #7e61e7 100%);
    padding: 0.6rem 2rem;
    border: none;
    outline: 1.5px solid transparent;
    border-radius: 0.2rem;
    cursor: pointer;
    transition: all 0.3s ease;
}
  
.contact-btn a{
    text-decoration: none;
}
  
.contact-btn:hover{
    color: #a993fe;
    background: #000;
    outline: 1.5px solid #a993fe;
}
  
.menu-btn {
    width: 4rem;
    height: 3rem;
    font-size: 3rem;
    display: flex;
    align-items: center;
    line-height: 0;
    color: #fff;
    justify-content: center;
    border: none;
    border-radius: 0.4rem;
    background: linear-gradient(90deg, #a993fe 2%, #7e61e7 98%);
    cursor: pointer;
    transition: all 0.3s ease;
    display: none;
}
  
@media (max-width: 425px) {
    .logo{
      width: 10rem;
      height: auto;
    }
}
  
@media (max-width: 769px) {
    .menu-btn{
      display: block;
    }
    .nav-content ul{
      display: none;
    }
  
}
  
@media (max-width: 1325px) {
    .nav-wrapper{
      padding: 0 2rem;
    }
}