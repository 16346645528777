.work-experience-card{
    border-radius: 0.65rem;
    border: 1.5px solid #6751b9;
    padding: 1.5rem;
    margin: 0 1rem;
    position: relative;
}
  
.work-experience-card h6{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0.6rem;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.downloadcerts-btn {
    width: 50px;
    height: 40px;
    cursor: pointer;
    margin-left: 1rem;
    background: none;
    border: none;
    padding: 0;
}

.download-img {
    width: 40px;
    height: 40px;
}
  
.work-duration{
    display: inline-block;
    font-size: 0.8rem;
    font-weight: 400;
    background: rgba(103, 81, 185, 0.5);
    border-radius: 0.3rem;
    padding: 0.4rem 0.6rem;
}
  
.work-experience-card ul li{
    list-style: none;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    position: relative;
}
  
.work-experience-card ul li::before{
    content: " ";
    width: 0.5rem;
    height: 0.5rem;
    background-color: #6751b9;
    border-radius: 0.5rem;
    position: absolute;
    left: -1.3rem;
    top: 0.5rem;
}

@media (max-width: 768px) {
    .work-experience-card{
        margin: 0;
    }
       .work-experience-card h6{
        font-size: 0.95rem;
    }
       .work-experience-card ul li{
        font-size: 0.75rem;
    }
        .downloadcerts-btn{
        width: 50px;
        height: 30px;
    }
        .download-img{
        width: 30px;
        height: 30px;
        }
}