.contact-details-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #130f2a;
    border-radius: 0.65rem;
    border: 1.5px solid #6751b9;
    padding: 1rem;
    margin-bottom: 2rem;
}
  
.contact-details-card .icon-image{
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    background: #3d3072;
    margin-bottom: 0.8rem;
}
  
.icon-image img{
    width: 1.8rem;
    height: auto;
    object-fit: contain;
}
  
.contact-details-card p{
    font-size: 0.9rem;
    font-weight: 400;
}